import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import { Nav, Tab } from 'react-bootstrap'
import { FaBook, FaEdit } from 'react-icons/fa'
import Skeleton from 'react-loading-skeleton'
import { Page } from './CompositionTheme.styles'
import UserComposition from './UserComposition/UserComposition'
import { CompositionThemeContext } from '@/contexts/CompositionTheme'
import Proposal from './Proposal/Proposal'
import useExited from '@/hooks/CompositionTheme/useExited'
import useTimeExpired from '@/hooks/CompositionTheme/useTimeExpired'
import useWantsToExit from '@/hooks/CompositionTheme/useWantsToExit'
import useOneMinuteLeft from '@/hooks/CompositionTheme/useOneMinuteLeft'

const CompositionTheme: React.FC = () => {
  const { $compositionTheme } = useContext(CompositionThemeContext)

  useOneMinuteLeft()
  useTimeExpired()
  useExited()

  const { containerRef } = useWantsToExit()

  return (
    <Page className="pt-5 pb-5">
      <div className="container mb-3">
        <div className="d-flex justify-content-between align-items-center mb-3">
          <h3>Tema</h3>

          <Link to="/" className="btn btn-primary">
            Voltar para home
          </Link>
        </div>

        <div className="composition-name">
          <h5 className="mb-0">
            {$compositionTheme.data?.name ?? <Skeleton />}
          </h5>
        </div>
      </div>

      <div ref={containerRef} className="container">
        <Tab.Container defaultActiveKey="proposal">
          <Nav variant="pills" className="flex-row justify-content-center mb-3">
            <Nav.Item>
              <Nav.Link className="pe-4 ps-4" eventKey="proposal">
                <FaBook size={16} /> <span>Ver proposta</span>
              </Nav.Link>
            </Nav.Item>

            <Nav.Item>
              <Nav.Link className="pe-4 ps-4" eventKey="composition">
                <FaEdit size={16} /> <span>Escrever redação</span>
              </Nav.Link>
            </Nav.Item>
          </Nav>

          <Tab.Content>
            <Tab.Pane eventKey="proposal">
              <Proposal />
            </Tab.Pane>

            <Tab.Pane eventKey="composition">
              <UserComposition />
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>
      </div>
    </Page>
  )
}

export default CompositionTheme
