import axios from 'axios'
import { QueryFunction } from '@tanstack/react-query'
import IAssessment from '@/interfaces/IAssessment'

const all: QueryFunction<IAssessment[]> = () => axios.get('assessments').then(({ data }) => data)

const find: QueryFunction<IAssessment> = ({ queryKey: [ , id ] }) => {
  return axios.get(`assessments/${id}`).then(({ data }) => data)
}

const $Assessment = {
  all,
  find,
}

export default $Assessment
