import styled from 'styled-components'

export const Card = styled.div`
  > .card-header {
    background-color: var(--tertiary-color);

    > .criteria {
      > .tag {
        width: 36px;
        height: 36px;
        line-height: 36px;
        text-align: center;
        background-color: var(--primary-color);
        border-radius: 0.375rem;
        font-weight: 500;
        color: #fff;
      }

      > .card-title {
        color: var(--text-color);

        > .btn-link {
          text-decoration: underline;
          color: var(--text-color);
        }
      }
    }

    > .grade {
      text-align: center;

      > small {
        text-transform: uppercase;
        line-height: 1.125rem;
      }

      > .value {
        font-size: 1.4rem;
        line-height: 1rem;
        font-weight: 500;
      }
    }
  }

  > .card-body {
    border: 1px solid var(--bg-color);
    border-top: 0;
    border-radius: 0 0 0.375rem 0.375rem;
    color: var(--text-color);
  }
`
