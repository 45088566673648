import React, { useMemo } from 'react'
import { Spinner } from 'react-bootstrap'
import { useMutation, useQuery } from '@tanstack/react-query'
import IAssessment from '@/interfaces/IAssessment'
import Evaluation from './Evaluation/Evaluation'
import OpenEvaluationStatusEnum from '@/enums/OpenEvaluationStatus'
import { Modal, Accordion } from './Assessment.styles'
import $Evaluation from '@/services/Evaluation'
import $SSO from '@/services/SSO'

type Props = {
  assessment: IAssessment | null
  show: boolean
  onClose: () => void
}

const Assessment: React.FC<Props> = ({ assessment, show, onClose }) => {
  const { lc, mt, cn, ch } = assessment || {
    lc: false,
    mt: false,
    cn: false,
    ch: false,
  }

  const canShowResult = (
    lc || mt || cn || ch
  )

  const { isLoading, data: evaluations } = useQuery({
    queryKey: [
      'evaluations', assessment?.id,
    ],
    queryFn: $Evaluation.all,
    enabled: !!assessment?.id,
  })

  const { mutateAsync, isPending, isSuccess } = useMutation({
    mutationFn: $SSO.assessment
  })

  const available = useMemo(() => {
    if (!evaluations)
      return []

    return evaluations.filter(({ openEvaluation: { status } }) => {
      return status !== OpenEvaluationStatusEnum.Completed
    })
  }, [evaluations])

  const completed = useMemo(() => {
    if (!evaluations)
      return []

    return evaluations.filter(({ openEvaluation: { status } }) => {
      return status === OpenEvaluationStatusEnum.Completed
    })
  }, [evaluations])

  const redirectToResult = async () => {
    if (!assessment || !canShowResult)
      return

    const { data: { redirectUrl } } = await mutateAsync(assessment.id)

    window.location.href = redirectUrl
  }

  return (
    <Modal show={show} onHide={onClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>
          <h3 className="mb-0">{assessment?.name}</h3>
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        {!isLoading ? assessment && evaluations && evaluations.length > 0 ? (
          <>
            <Accordion defaultActiveKey={[ '0', '1' ]} alwaysOpen flush>
              {available.length > 0 && (
                <Accordion.Item eventKey="0">
                  <Accordion.Header>Provas não finalizadas</Accordion.Header>

                  <Accordion.Body className="p-0">
                    <div className="evaluations">
                      {available.map((evaluation) => (
                        <Evaluation evaluation={evaluation} key={evaluation.id} />
                      ))}
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              )}

              {completed.length > 0 && (
                <Accordion.Item eventKey="1">
                  <Accordion.Header>Provas finalizadas</Accordion.Header>

                  <Accordion.Body className="p-0">
                    <div className="evaluations">
                      {completed.map((evaluation) => (
                        <Evaluation evaluation={evaluation} key={evaluation.id} />
                      ))}
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              )}
            </Accordion>

            <div className="btn-result">
              <button className="btn btn-primary" onClick={redirectToResult} disabled={!canShowResult || isPending || isSuccess}>
                {canShowResult ? (isPending ? (
                  <>
                    Aguarde... <Spinner animation="border" size="sm" />
                  </>
                ) : isSuccess ? (
                  'Redirecionando...'
                ) : (
                  'Ver resultado detalhado'
                )) : (
                  'Aguarde o resultado detalhado'
                )}
              </button>
            </div>
          </>
        ) : (
          <div className="d-flex justify-content-center align-items-center">
            <p className="text-muted mb-0">Nenhuma avaliação disponível para este simulado.</p>
          </div>
        ) : (
          <div className="d-flex justify-content-center align-items-center">
            <Spinner color="primary" animation="border" />
          </div>
        )}
      </Modal.Body>
    </Modal>
  )
}

export default Assessment
