import styled from 'styled-components'

export const Page = styled.div`
  > .alert {
    border: none;
    border-radius: 0;
    background-color: var(--secondary-color);
    font-size: var(--font-size-16px);
    font-weight: 500;
    color: #fff;
  }

  > .container {
    > .row {
      > .col {
        > .card {
          > .card-body {
            height: 480px;
            border-radius: 1rem;
            box-shadow: var(--box-shadow);
            padding: var(--padding);

            > .dashboard-component {
              height: 100%;
              display: flex;
              flex-direction: column;
              padding-bottom: var(--padding);
              gap: 2rem;

              > .labels {
                gap: 2rem;

                > .btn {
                  padding: 0;
                  text-align: center;
                  transition: color 0.2s ease;
                  flex-grow: 1;
                  border: none;
                  color: var(--base-color);

                  > span {
                    position: relative;
                    font-size: 0.8rem;

                    &::before {
                      content: '';
                      display: none;
                      width: 6px;
                      height: 6px;
                      border-radius: 50%;
                      background-color: var(--secondary-color);
                      position: absolute;
                      transform: translateY(-4px);
                      top: 50%;
                      left: -10px;
                    }
                  }

                  &.active {
                    color: var(--secondary-color);

                    > span::before {
                      display: block;
                    }
                  }
                }
              }

              > .theme-list {
                overflow-y: scroll;
                flex-grow: 1;

                > .theme-item {
                  width: 100%;
                  background-color: transparent;
                  border: none;
                  padding: 0;
                  display: flex;
                  align-items: center;
                  text-decoration: none;
                  border-bottom: 1px solid #f0f0f0;
                  padding-bottom: 0.5rem;
                  margin-bottom: 0.5rem;
                  gap: 0.5rem;

                  > .label {
                    width: 56px;
                    min-width: 56px;
                    height: 56px;
                    display: flex;
                    align-items: center;
                    flex-direction: column;
                    justify-content: center;
                    border: 1px solid var(--tertiary-color);
                    transition: border 0.2s ease, background-color 0.2s ease;
                    white-space: nowrap;
                    text-align: center;
                    border-radius: 4px;

                    > h6 {
                      display: block;
                      color: var(--primary-color);
                      transition: color 0.2s ease;
                    }

                    > small {
                      display: block;
                      font-size: 0.857rem;
                      position: relative;
                      transition: color 0.2s ease;
                      color: var(--base-color);
                    }
                  }

                  > .result {
                    height: 56px;
                    display: flex;
                    justify-content: center;
                    flex-direction: column;
                    transition: background-color 0.2s ease;
                    border-radius: 4px;
                    padding: 0.5rem;
                    flex-grow: 1;

                    > .wrapper > .title {
                      color: var(--text-color);
                      transition: color 0.2s ease
                    }
                  }

                  &.status-4 > .result {
                    background-color: var(--quaternary-color);
                  }

                  &:hover > .result {
                    background-color: var(--tertiary-color);

                    > .wrapper > .title {
                      color: var(--primary-color);
                    }
                  }

                  &:last-child {
                    margin-bottom: 0;
                    padding-bottom: 0;
                    border-bottom: none;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
