import { useCallback, useContext, useEffect } from 'react'
import { CompositionThemeContext } from '@/contexts/CompositionTheme'
import { CompositionState } from '@/models/Composition'

const useExited = () => {
  const { compositionRef, $update, isAvailable } = useContext(CompositionThemeContext)

  const onUnload = useCallback(() => {
    if (isAvailable) {
      compositionRef.current.swipe(CompositionState.Exited, (content) => {
        $update.mutate(content)
      })
    }
  }, [$update, compositionRef, isAvailable])

  const onBeforeUnload = useCallback((event: BeforeUnloadEvent) => {
    if (isAvailable) {
      event.preventDefault()
    }
  }, [isAvailable])

  useEffect(() => {
    window.addEventListener('beforeunload', onBeforeUnload)
    window.addEventListener('unload', onUnload)

    return () => {
      window.removeEventListener('beforeunload', onBeforeUnload)
      window.removeEventListener('unload', onUnload)
    }
  }, [onBeforeUnload, onUnload])
}

export default useExited
