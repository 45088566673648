import { DependencyList, EffectCallback, useEffect, useRef } from 'react'

const useUpdateEffect = <D extends DependencyList | undefined>(effect: EffectCallback, deps: D, predicate?: (deps: D) => boolean) => {
  const isMounted = useRef(false)

  useEffect(() => {
    if (isMounted.current) {
      return effect()
    }

    if (!predicate || predicate(deps)) {
      isMounted.current = true
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps)
}

export default useUpdateEffect
