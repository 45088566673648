import axios, { AxiosResponse } from 'axios'
import { MutationFunction, QueryFunction } from '@tanstack/react-query'
import Authentication from '@/types/Authentication'
import IUser from '@/interfaces/IUser'

const me: QueryFunction<IUser, [ string, string | null ]> = ({ queryKey: [ , token ] }) => {
  return axios.get('auth/me', {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then(({ data }) => data)
}

const login: MutationFunction<AxiosResponse<IUser>, Authentication> = authentication => {
  return axios.post('auth/login', authentication)
}

const $Auth = {
  login,
  me,
}

export default $Auth
