import styled from 'styled-components'

export const Card = styled.div`
  > .card-header,
  > .card-body {
    padding: calc(var(--padding) / 2);
  }

  > .card-body {
    height: 480px;

    > .proposal {
      height: 100%;
      overflow-y: scroll;
      padding-right: calc(var(--padding) / 2);
      line-height: 1.35rem;
      font-size: 0.925rem;

      h2 {
        margin-bottom: 0;
        color: var(--text-color);
      }
    }
  }

  > .card-footer {
    padding: 0.5rem calc(var(--padding) / 2);
  }
`
