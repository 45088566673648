import styled from 'styled-components'

export const Container = styled.div`
  border: 1px solid #eee;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  padding: 1rem;

  > .heading {
    display: flex;
    align-items: center;
    gap: 0.5em;

    > img {
      width: 2.4rem;
      height: 2.4rem;
      object-fit: contain;
    }

    > h5 {
      font-size: 1rem;
      font-weight: 600;
      margin-bottom: 0;
    }
  }

  > .description {
    > .date,
    > .area {
      display: flex;
      align-items: center;
      gap: 0.5rem;
    }

    > .date {
      > svg {
        position: relative;
        top: -2px;
      }

      > span {
        background-color: #f0f0f0;
        border-radius: 4px;
        padding: 0.25rem 0.5rem;
      }
    }
  }

  &.area-LC {
    > .heading > h5,
    > .description > .date > span {
      color: var(--area-LC-color);
    }

    > .btn-evaluation > .btn {
      background-color: var(--area-LC-color);
      border-color: var(--area-LC-color);
    }
  }

  &.area-CH {
    > .heading > h5,
    > .description > .date > span {
      color: var(--area-CH-color);
    }

    > .btn-evaluation > .btn {
      background-color: var(--area-CH-color);
      border-color: var(--area-CH-color);
    }
  }

  &.area-CN {
    > .heading > h5,
    > .description > .date > span {
      color: var(--area-CN-color);
    }

    > .btn-evaluation > .btn {
      background-color: var(--area-CN-color);
      border-color: var(--area-CN-color);
    }
  }

  &.area-MT {
    > .heading > h5,
    > .description > .date > span {
      color: var(--area-MT-color);
    }

    > .btn-evaluation > .btn {
      background-color: var(--area-MT-color);
      border-color: var(--area-MT-color);
    }
  }
`
