import { MutationFunction } from '@tanstack/react-query'
import axios, { AxiosResponse } from 'axios'

const __invoke: MutationFunction<AxiosResponse<{ redirectUrl: string }>, void> = () => axios.get('sso')

const assessment: MutationFunction<AxiosResponse<{ redirectUrl: string }>, number> = (id) => {
  return axios.post(`sso/assessment/${id}`)
}

const evaluation: MutationFunction<AxiosResponse<{ redirectUrl: string }>, number> = (id) => {
  return axios.post(`sso/evaluation/${id}`)
}

const $SSO = {
  __invoke,
  assessment,
  evaluation,
}

export default $SSO
