import React, { useContext, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Spinner } from 'react-bootstrap'
import AppContext from '@/contexts/App'
import axios from 'axios'

const Login: React.FC= () => {
  const { setToken } = useContext(AppContext)

  const navigate = useNavigate()

  const { search } = useLocation()

  useEffect(() => {
    const params = new URLSearchParams(search)

    if (!params.has('token'))
      return

    const token: string = params.get('token') as string

    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`

    setToken(token)

    setTimeout(() => {
      navigate('/')
    }, 1000)
  }, [navigate, search, setToken])

  return (
    <div className="vh-100 d-flex justify-content-center align-items-center">
      <Spinner animation="border" variant="primary" />
    </div>
  )
}

export default Login
