import queryClient from './config/queryClient'
import './config/document'
import './config/axios'
import './config/highcharts'
import './config/moment'

import './../assets/scss/app.scss'

export const config = {
  queryClient,
}
