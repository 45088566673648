import styled from 'styled-components'
import { Modal as ModalComponent } from 'react-bootstrap'

export const Modal = styled(ModalComponent)`
  text-align: center;

  > .modal-dialog > .modal-content > .modal-body {
    text-align: center;
    overflow-y: scroll;
    font-size: var(--font-size-16px);
    height: 600px;

    > .form-group {
      max-width: 240px;
      margin: 0 auto;

      > .form-control {
        font-size: var(--font-size-16px);
      }
    }

    > .nav {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      max-width: 600px;
      column-gap: 1rem;
      margin: auto;

      > .nav-item {
        > .nav-link {
          font-size: 1.2rem;
          color: var(--primary-color);
          text-transform: uppercase;
          padding: 0 0.5rem;
          font-weight: 500;

          &.active {
            font-weight: 600;
            border-bottom-color: var(--secondary-color);
          }
        }
      }
    }

    > .tab-content > .tab-pane > .container {
      text-align: left;

      .btn-primary {
        max-width: 160px;
        background-color: var(--primary-color);
        border-color: var(--primary-color);
        text-transform: uppercase;
      }

      hr {
        border-top: 8px solid var(--secondary-color);
        opacity: 1;
      }
    }
  }
`
