import Swal, { SweetAlertOptions, SweetAlertResult } from 'sweetalert2'
import withReactContent, { ReactSweetAlert } from 'sweetalert2-react-content'

type SweetAlert = typeof Swal & ReactSweetAlert & {
  confirm: (options: SweetAlertOptions) => Promise<SweetAlertResult>
}

const useSwal = () => {
  const swal = withReactContent(Swal) as SweetAlert

  swal.confirm = async (options: SweetAlertOptions) => {
    return swal.fire({
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não',
      ...options,
    })
  }

  return swal
}

export default useSwal
