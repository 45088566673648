export enum CompositionState {
  TimeExpired,
  Submitted,
  Exited,
}

class Composition {
  private state: CompositionState | null = null
  private content: string | null = null
  private dispatch: React.Dispatch<React.SetStateAction<string | null>>

  constructor(dispatch: React.Dispatch<React.SetStateAction<string | null>>) {
    this.dispatch = dispatch
  }

  public swipe(state: CompositionState, onSwiped: (content: string | null) => void): boolean {
    if (this.state !== null)
      return false

    this.setState(state)

    onSwiped(this.content)

    return true
  }

  public setState(state: CompositionState | null): void {
    this.state = state
  }

  public getState(): CompositionState | null {
    return this.state
  }

  public setContent(content: string | null): void {
    this.dispatch(() => {
      this.content = content
      return content
    })
  }

  public getContent(): string | null {
    return this.content
  }
}

export default Composition
