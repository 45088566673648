import React, { useCallback, useEffect } from 'react'
import IClient from '@/interfaces/IClient'
import { hexToRgb } from '@/helpers/Color'
import useStickyState from './useStickyState'

type Theme = Pick<IClient, 'name' | 'primaryColor' | 'secondaryColor' | 'tertiaryColor' | 'quaternaryColor' | 'favicon'>

type Colors = {
  '--primary-color': string
  '--secondary-color': string
  '--tertiary-color': string
  '--quaternary-color': string
}

const useClient = () => {
  const [colors, setColors] = useStickyState<Colors | null>('colors', null)
  const [favicon, setFavicon] = useStickyState<string | null>('favicon', null)

  useEffect(() => {
    if (!colors)
      return

    Object.entries(colors).forEach(([key, hex]) => {
      document.documentElement.style.setProperty(key, hex);
      document.documentElement.style.setProperty(key + '-rgb', hexToRgb(hex).join(', '));
    })
  }, [colors])

  useEffect(() => {
    if (!favicon)
      return

    (document.querySelector("link[rel*='icon']") as HTMLLinkElement).href = favicon
  }, [favicon])

  const setClient = useCallback((client: Theme) => {
    document.title = client.name

    if (client.favicon) {
      setFavicon(client.favicon)
    }

    setColors({
      '--primary-color': client.primaryColor,
      '--secondary-color': client.secondaryColor,
      '--tertiary-color': client.tertiaryColor,
      '--quaternary-color': client.quaternaryColor,
    })
  }, [setColors, setFavicon])

  return {
    setClient,
  }
}

export default useClient
