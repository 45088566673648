import { useEffect, useState } from 'react'

function useStickyState<T>(key: string, defaultValue: T): [T, React.Dispatch<React.SetStateAction<T>>] {
  const [value, setValue] = useState<T>(() => {
    const stickyValue = localStorage.getItem(key)

    if (!stickyValue)
      return defaultValue

    try {
      return JSON.parse(stickyValue)[key] as T
    } catch (error) {
      return defaultValue
    }
  })

  useEffect(() => {
    localStorage.setItem(key, JSON.stringify({
      [key]: value,
    }))
  }, [key, value])

  return [value, setValue]
}

export default useStickyState
