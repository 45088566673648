import React from 'react'

type Props<T> = {
  label: string
  status: T | null,
  value: T,
  progress: number
  onClick: (value: T) => void
}

const Tab: React.FC<Props<number | 0>> = ({ label, status, value, progress, onClick }) => {
  return (
    <button className={`btn ${status === value ? 'active' : ''}`} onClick={() => onClick(value)}>
      <span>{label}</span>

      <div className="progress">
        <div style={{ width: `${progress}%` }} className="progress-bar" />
      </div>
    </button>
  )
}

export default Tab
