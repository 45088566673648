import React, { useMemo, useState } from 'react'
import moment from 'moment'
import AssessmentStatusEnum from '@/enums/AssessmentStatus'
import IAssessment from '@/interfaces/IAssessment'
import Skeleton from 'react-loading-skeleton'
import Assessment from './Assessment/Assessment'
import Tab from '../Tab/Tab'

type Props = {
  isLoading: boolean
  data: IAssessment[]
}

const Assessments: React.FC<Props> = ({ isLoading, data: assessments }: Props) => {
  const [ status, setStatus ] = useState<AssessmentStatusEnum | null>(null)
  const [ assessment, setAssessment ] = useState<IAssessment | null>(null)
  const [ showModal, setShowModal ] = useState<boolean>(false)

  const openAssessmentModal = (assessment: IAssessment) => {
    setAssessment(assessment)
    setShowModal(true)
  }

  const data = useMemo(() => {
    return assessments.filter(assessment => (
      status === null || assessment.status === status
    ))
  }, [assessments, status])

  const onClick = (newStatus: typeof status) => {
    if (status === newStatus) {
      setStatus(null)
    } else {
      setStatus(newStatus)
    }
  }

  const getProgress = ({ status }: IAssessment) => {
    switch (status) {
      case AssessmentStatusEnum.Available:
        return 50
      case AssessmentStatusEnum.Final:
        return 100
      default:
        return 0
    }
  }

  return (
    <div className="dashboard-component">
      <Assessment show={showModal} assessment={assessment} onClose={() => setShowModal(false)} />

      <div className="labels d-flex justify-content-between">
        <Tab
          label="Indisponível"
          progress={0}
          status={status}
          value={AssessmentStatusEnum.Unavailable}
          onClick={onClick}
        />

        <Tab
          label="Disponível"
          progress={50}
          status={status}
          value={AssessmentStatusEnum.Available}
          onClick={onClick}
        />

        <Tab
          label="Finalizado"
          progress={100}
          status={status}
          value={AssessmentStatusEnum.Final}
          onClick={onClick}
        />
      </div>

      <div className="theme-list">
        {!isLoading ? (
          data.length > 0 ? data.map(assessment => (
            <button className="theme-item" key={assessment.id} onClick={() => openAssessmentModal(assessment)}>
              <div className="label">
                <h5 className="mb-0">{moment(assessment.date).format('MMM').toUpperCase()}</h5>
                <small>{moment(assessment.date).format('Y')}</small>
              </div>

              <div className="result">
                <div className="wrapper">
                  <div className="title">{assessment.name}</div>
                  <div className="progress">
                    <div style={{ width: `${getProgress(assessment)}%` }} className="progress-bar" />
                  </div>
                </div>
              </div>
            </button>
          )) : (
            <div className="text-center">
              <small className="mb-0">Nenhum simulado encontrado.</small>
            </div>
          )
        ) : (
          <Skeleton className="mb-3" height={56} count={3} />
        )}
      </div>
    </div>
  )
}

export default Assessments
