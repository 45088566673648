import React, { useEffect, useMemo, useState } from 'react'
import { Modal } from './Subscription.styles'
import data from '@/institutions.json'
import { Nav, Tab } from 'react-bootstrap'

interface IIstitution {
  name: string
  region: string
  description: string
  logotipo: string
}

type Props = {
  show: boolean
  setShow: (value: boolean) => void
}

const Subscription: React.FC<Props> = ({ show, setShow }: Props) => {
  const [ region, setRegion ] = useState<string>('')
  const [ activeKey, setActiveKey ] = useState<string>('')

  const institutions = (data as unknown as IIstitution[])

  const regions = useMemo(() => (data as unknown as IIstitution[]).reduce((acc, institution) => {
    if (!acc.includes(institution.region))
      acc.push(institution.region)

    return acc
  }, [] as string[]), [])

  const results = useMemo(() => institutions.filter(institution => {
    return region.length > 0 ? institution.region === region : true
  }, []).sort((a, b) => {
    return a.region.localeCompare(b.region)
  }), [institutions, region])

  useEffect(() => {
    if (!results.some(item => item.name === activeKey)) {
      setActiveKey(results[0].name)
    }
  }, [activeKey, region.length, results])

  const onActiveKeyChange = (key: string | null) => {
    if (key) setActiveKey(key)
  }

  return (
    <Modal show={show} onHide={() => setShow(false)} size="lg" centered>
      <Modal.Header closeButton>
        <Modal.Title className="mb-0">Ânima Educação</Modal.Title>
      </Modal.Header>

      <Modal.Body className="p-3">
        <div>
          <p>
            Agora que você concluiu simulados do Enem Fácil, utilize o desempenho obtido nas avaliações para se inscrever em uma de nossas instituições de Ensino Superior.
            <br/>
            <br/>
            <strong>Escolha uma de nossas instituições abaixo e faça sua inscrição agora mesmo!</strong>
          </p>
        </div>

        <div className="form-group d-flex align-items-center justify-content-center gap-3 mb-5">
          <label htmlFor="region">Região:</label>
          <select id="region" className="form-control" onChange={e => setRegion(e.target.value)}>
            <option value="">Todas</option>
            {regions.map(region => (
              <option key={region} value={region}>{region}</option>
            ))}
          </select>
        </div>

        <Tab.Container activeKey={activeKey} onSelect={onActiveKeyChange}>
          <Nav variant="underline" className="mb-5">
            {results.map(({ name }) => (
              <Nav.Item key={name}>
                <Nav.Link eventKey={name}>{name}</Nav.Link>
              </Nav.Item>
            ))}
          </Nav>

          <Tab.Content>
            {institutions.map(({ name, description, logotipo }) => (
              <Tab.Pane eventKey={name} key={name}>
                <div className="container">
                  <div className="d-flex justify-content-between align-items-center mb-5">
                    <figure className="mb-0">
                      <img src={require(`@/assets/imgs/institutions/${logotipo}`)} alt={name} />
                    </figure>

                    <button className="btn btn-primary" disabled>
                      Quero me inscrever!
                    </button>
                  </div>

                  <div className="row">
                    <div className="col-2">
                      <hr/>
                    </div>

                    <div className="col-10">
                      <p dangerouslySetInnerHTML={{ __html: description }} />
                    </div>
                  </div>
                </div>
              </Tab.Pane>
            ))}
          </Tab.Content>
        </Tab.Container>
      </Modal.Body>
    </Modal>
  )
}

export default Subscription
