import { useContext, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { CompositionThemeContext } from '@/contexts/CompositionTheme'
import { getTimeRemaining } from '@/helpers/Time'
import { CompositionState } from '@/models/Composition'
import useSwal from '@/hooks/useSweetAlert'
import CompositionThemeStatus from '@/enums/CompositionThemeStatus'

const useTimeExpired = () => {
  const { id, $compositionTheme, compositionRef, setIsTimeExpired } = useContext(CompositionThemeContext)

  const swal = useSwal()

  const navigate = useNavigate()

  useEffect(() => {
    if (!$compositionTheme.data)
      return

    const { counterTime, userComposition: { createdAt }, status } = $compositionTheme.data

    if (!counterTime || status !== CompositionThemeStatus.Available)
      return

    const timeRemaining = getTimeRemaining(counterTime, new Date(createdAt))

    const timeout = setTimeout(async () => {
      compositionRef.current.swipe(CompositionState.TimeExpired, () => {
        setIsTimeExpired(true)

        swal.fire({
          title: 'Tempo esgotado',
          html: (
            '<p>O tempo para a redação expirou</p>' +
            '<p>A sua redação será finalizada e enviada automaticamente.</p>' +
            '<strong>Obrigado por participar!</strong>'
          ),
          icon: 'warning',
          showCloseButton: false,
          showCancelButton: false,
          allowOutsideClick: false,
          showConfirmButton: true,
          confirmButtonText: 'Ver resultado',
        }).then(() => {
          navigate(`/redacao/${id}/resultado`)
        })
      })
    }, timeRemaining * 1000)

    return () => {
      clearTimeout(timeout)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [$compositionTheme])
}

export default useTimeExpired
