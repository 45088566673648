import CompositionCriteriaEnum from '@/enums/CompositionCriteria'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { Card } from './Comment.styles'
import { Modal } from 'react-bootstrap'

type Props = {
  tag: keyof typeof CompositionCriteriaEnum
  comment: [ string, string ]
  value: number
}

const videoIds = {
  c1: 'Kx7QsNfX-Gw',
  c2: '4HOg_YGrO8c',
  c3: 'LVs4xHJZ_PA',
  c4: 'vz3YvOLf-vg',
  c5: 'UyUObq0Ev8A',
} as Record<keyof typeof CompositionCriteriaEnum, string>

const Comment: React.FC<Props> = ({ tag, comment, value }: Props) => {
  const [ compositionCriteria, setCompositionCriteria ] = useState<keyof typeof CompositionCriteriaEnum | null>(null)

  return (
    <>
      <Modal size="lg" show={!!compositionCriteria} onHide={() => setCompositionCriteria(null)} centered>
        <Modal.Header closeButton>
          <Modal.Title>{CompositionCriteriaEnum[tag]}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <iframe
            width="100%"
            height="420"
            src={`https://www.youtube.com/embed/${videoIds[compositionCriteria as keyof typeof videoIds]}`}
            title="YouTube video player"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerPolicy="strict-origin-when-cross-origin"
            allowFullScreen
          />
        </Modal.Body>
      </Modal>

      <Card className="card">
        <div className="card-header d-flex justify-content-between align-items-center">
          <div className="criteria d-flex gap-3">
            <div className="tag">{tag.toUpperCase()}</div>

            <div className="card-title mb-0">
              <h6 className="mb-0">
                {CompositionCriteriaEnum[tag]}
              </h6>

              <button className="btn btn-link p-0" onClick={() => setCompositionCriteria(tag)}>
                Conheça mais sobre essa competência
              </button>
            </div>
          </div>

          <div className="grade">
            <small>Nota</small>
            <div className="value">{value}</div>
          </div>
        </div>

        <div className="card-body">
          <p className="card-text">
            <strong>Desempenho:</strong>
          </p>

          <p className="card-text">
            {comment[0]}
          </p>

          <p className="card-text">
            <i>{comment[1]}</i>
          </p>
        </div>
      </Card>
    </>
  )
}

export default Comment
