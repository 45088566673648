import React, { useContext, useMemo } from 'react'
import { CompositionThemeContext } from '@/contexts/CompositionTheme'
import { Spinner } from 'react-bootstrap'
import { Card } from './UserComposition.styles'
import Countdown from '../Contdown/Contdown'
import useSubmitted from '@/hooks/CompositionTheme/useSubmitted'
import Textarea from './Textarea/Textarea'

const UserComposition: React.FC = () => {
  const { content, isAvailable, isTimeExpired, isFinished } = useContext(CompositionThemeContext)

  const { onSubmit } = useSubmitted()

  const isDisabled = useMemo(() => {
    return !isAvailable || content === null || content.length < 1000 || isTimeExpired || isFinished
  }, [content, isAvailable, isTimeExpired, isFinished])

  return (
    <form onSubmit={onSubmit}>
      <Card className="card">
        <div className="card-header">
          <h6 className="mb-0">Área de escrita</h6>
          <Countdown />
        </div>

        <div className="card-body">
          <p className="mb-0">
            Serão considerados textos insuficientes aqueles que não atingirem o mínimo de 1000 caracteres.
            Considere como limite máximo 3500 caracteres, o que corresponderia, no papel, a aproximadamente 30 linhas manuscritas.
          </p>
          <Textarea />
        </div>

        <div className="card-footer d-flex justify-content-between align-items-center">
          <div className="characters">
            Caracteres: {content?.length} / 3500
          </div>

          <button type="submit" className="btn btn-secondary pe-4 ps-4 d-flex align-items-center gap-2" disabled={isDisabled}>
            <span>Finalizar</span> {(isTimeExpired || isFinished) && (
              <Spinner animation="border" color="primary" size="sm" />
            )}
          </button>
        </div>
      </Card>
    </form>
  )
}

export default UserComposition
