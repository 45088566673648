import Highcharts from 'highcharts'

Highcharts.setOptions({
  title: {
    text: undefined,
  },
  credits: {
    enabled: false,
  },
  chart: {
    style: {
      fontFamily: 'Evo',
      fontWeight: '400',
      fontSize: '16px',
    },
  },
})
