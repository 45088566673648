import styled from 'styled-components'

export const Card = styled.div`
  > .card-header,
  > .card-body {
    padding: calc(var(--padding) / 2);
  }

  > .card-body {
    height: calc(480px - 1rem);
    display: flex;
    flex-direction: column;
    gap: calc(var(--padding) / 2);

    > .form-control {
      flex-grow: 1;
      position: relative;
      padding: var(--padding);
      font-size: 1.125rem;

      &::-webkit-input-placeholder {
        font-size: 1.15rem;
        position: absolute;
        transform: translate(-50%, -50%);
        text-align: center;
        font-weight: 500;
        color: #bdbdbd;
        left: 50%;
        top: 50%;
      }
    }
  }

  > .card-footer {
    padding: 0.5rem calc(var(--padding) / 2);
  }
`
