import React, { useCallback, useMemo, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import Skeleton from 'react-loading-skeleton'
import moment from 'moment'
import CompositionThemeStatus from '@/enums/CompositionThemeStatus'
import ICompositionTheme from '@/interfaces/ICompositionTheme'
import Tab from '../Tab/Tab'
import IUserComposition from '@/interfaces/IUserComposition'
import useSwal from '@/hooks/useSweetAlert'
import UserCompositionStatus from '@/enums/UserCompositionStatus'

type CompositionTheme = Omit<ICompositionTheme, 'userComposition'> & {
  userComposition: IUserComposition | null
}

type Props = {
  isLoading: boolean
  data: CompositionTheme[]
}

const Compositions: React.FC<Props> = ({ isLoading, data: compositionThemes }: Props) => {
  const [ status, setStatus ] = useState<CompositionThemeStatus | null>(null)

  const swal = useSwal()
  const navigate = useNavigate()

  const data = useMemo(() => {
    return compositionThemes.filter(compositionTheme => (
      status === null || compositionTheme.status === status
    ))
  }, [compositionThemes, status])

  const onClick = (newStatus: typeof status) => {
    if (status === newStatus) {
      setStatus(null)
    } else {
      setStatus(newStatus)
    }
  }

  const getProgress = (status: CompositionThemeStatus) => {
    switch (status) {
      case CompositionThemeStatus.Available:
        return 50
      case CompositionThemeStatus.Final:
        return 100
      default:
        return 0
    }
  }

  const open = useCallback((event: React.MouseEvent<HTMLAnchorElement, MouseEvent>, { id, name: title, status, counterTime, availableAt, userComposition }: CompositionTheme) => {
    event.preventDefault()

    if (new Date(availableAt) > new Date())
      return

    if (status === CompositionThemeStatus.Final) {
      navigate(`/redacao/${id}/resultado`)
      return
    }

    if (userComposition?.status === UserCompositionStatus.InProgress) {
      navigate(`/redacao/${id}`)
      return
    }

    swal.confirm({
      title,
      html: (
        !counterTime ? (
          '<p>Ao prosseguir, a redação será iniciada. Caso você saia da tela, seu texto será salvo e você poderá continuar depois, até o fim da janela de aplicação.</p>' +
          '<strong>Deseja iniciar a redação?</strong>'
        ) : (
          `<p>Ao prosseguir, a redação será iniciada e você terá ${Math.round(moment.duration(counterTime, 'seconds').asMinutes())} minutos para finalizá-la. Caso o tempo termine, sua redação será enviada automaticamente.</p>` +
          '<strong>Deseja iniciar a redação?</strong>'
        )
      ),
    }).then(({ isConfirmed }) => {
      if (isConfirmed) {
        navigate(`/redacao/${id}`)
      }
    })
  }, [navigate, swal])

  return (
    <div className="dashboard-component">
      <div className="labels d-flex justify-content-between">
        <Tab
          label="Indisponível"
          progress={0}
          status={status}
          value={CompositionThemeStatus.Unavailable}
          onClick={onClick}
        />

        <Tab
          label="Disponível"
          progress={50}
          status={status}
          value={CompositionThemeStatus.Available}
          onClick={onClick}
        />

        <Tab
          label="Finalizado"
          progress={100}
          status={status}
          value={CompositionThemeStatus.Final}
          onClick={onClick}
        />
      </div>

      <div className="theme-list">
        {!isLoading ? (
          data.length > 0 ? data.map(compositionTheme => {
            const { id, name, status, availableAt } = compositionTheme

            return (
              <Link to="/" className={`theme-item status-${status}`} onClick={event => open(event, compositionTheme)} key={id}>
                <div className="label">
                  <h5 className="mb-0">
                    {moment(availableAt).format('MMM').toUpperCase()}
                  </h5>

                  <small>
                    {moment(availableAt).format('Y')}
                  </small>
                </div>

                <div className="result">
                  <div className="wrapper">
                    <div className="title">{name}</div>
                    <div className="progress">
                      <div style={{ width: `${getProgress(status)}%` }} className="progress-bar" />
                    </div>
                  </div>
                </div>
              </Link>
            )
          }) : (
            <div className="text-center">
              <small className="mb-0">Nenhuma redação encontrada.</small>
            </div>
          )
        ) : (
          <Skeleton className="mb-3" height={56} count={3} />
        )}
      </div>
    </div>
  )
}

export default Compositions
