import React from 'react'
import { Link } from 'react-router-dom'
import { ReactComponent as Logotipo } from '@/assets/imgs/logotipo.svg'
import { Container } from './Footer.styles'

const Footer: React.FC = () => {
  return (
    <Container>
      <div className="container-fluid d-flex justify-content-center align-items-center">
        <figure className="logotipo mb-0">
          <Link to="/">
            <Logotipo />
          </Link>
        </figure>
      </div>
    </Container>
  )
}

export default Footer
