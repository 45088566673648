import React, { useContext } from 'react'
import { CompositionThemeContext } from '@/contexts/CompositionTheme'
import { Card } from './Proposal.styles'
import Countdown from '../Contdown/Contdown'
import Skeleton from 'react-loading-skeleton'

const Proposal: React.FC = () => {
  const { $compositionTheme } = useContext(CompositionThemeContext)

  return (
    <Card className="card">
      <div className="card-header">
        <h6 className="mb-0">Proposta de redação</h6>
        <Countdown />
      </div>

      <div className="card-body">
        {$compositionTheme.data ? (
          <div className="proposal" dangerouslySetInnerHTML={{ __html: $compositionTheme.data.proposalText ?? '' }} />
        ) : (
          <>
            <div className="mb-3">
              <Skeleton width={160} />
            </div>

            <div className="mb-3">
              <Skeleton count={3} />
            </div>

            <div className="mb-3">
              <Skeleton width={200} />
            </div>

            <div className="mb-3">
              <Skeleton count={3} />
            </div>

            <div className="mb-3">
              <Skeleton width={220} />
            </div>

            <div>
              <Skeleton count={2} />
              <Skeleton width={150} />
            </div>
          </>
        )}
      </div>

      <div className="card-footer"></div>
    </Card>
  )
}

export default Proposal
