import styled from 'styled-components'

export const Page = styled.div`
  > .container {
    > h5 {
      color: var(--secondary-color);
    }

    > .composition-name {
      background-color: #fff;
      border-radius: var(--border-radius);
      box-shadow: var(--box-shadow);
      padding: 1rem 1.6rem;

      > h5 {
        color: var(--text-color);
        font-weight: 500;
        font-size: 1rem;
      }
    }
  }
`
