import React, { useCallback, useEffect, useMemo } from 'react'
import Highcharts, { Options, SeriesOptionsType } from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import IAssessment from '@/interfaces/IAssessment'
import moment from 'moment'
import AssessmentStatusEnum from '@/enums/AssessmentStatus'

const defaultOptions = {
  plotOptions: {
    line: {
      dataLabels: {
        enabled: true,
      },
      enableMouseTracking: false,
    },
    series: {
      dataLabels: {
        style: {
          fontSize: '16px',
          fontWeight: '400',
        },
      },
    },
  },
  xAxis: {
    type: 'category',
    uniqueNames: true,
    lineWidth: 0,
    title: {
      text: undefined,
    },
    labels: {
      formatter: ({ value }: any) => {
        return (`
          <div style="width: 48px; height: 48px; text-align: center; border: 1px solid var(--bg-color); border-radius: 5px; display: flex; justify-content: center; flex-direction: column; gap: 2px;">
            <h6 style="color: var(--primary-color); margin-bottom: 0;">${value}</h6>
            <small style="color: var(--base-color);">2024</small>
          </div>
        `)
      },
      useHTML: true,
    },
  },
  yAxis: {
    title: {
      text: undefined,
    },
    min: 0,
    max: 1000,
    tickInterval: 200,
  },
}

type Props = {
  isLoading: boolean
  data: IAssessment[]
}

const Grade: React.FC<Props> = ({ isLoading, data }: Props) => {
  const highchartsRef = React.useRef<HighchartsReact.RefObject>(null)

  const assessments = useMemo(() => data.filter(({ status }) => {
    return status === AssessmentStatusEnum.Final
  }), [data])

  const months = useMemo(() => {
    return assessments.map(({ date }) => (
      moment(date).format('MMM').toUpperCase()
    )).reduce((acc, month) => {
      if (!acc.includes(month))
        acc.push(month)

      return acc
    }, [] as string[])
  }, [assessments])

  const getData = useCallback((predicate: (assessment: IAssessment) => number) : [string, number][] => {
    const data: [string, number][] = []

    months.forEach(month => {
      assessments.filter(({ date }) => (
        moment(date).format('MMM').toUpperCase() === month
      )).map(assessment => {
        return predicate(assessment)
      }).forEach(value => {
        data.push([month, value])
      })
    })

    return data
  }, [assessments, months])

  const options: Options = useMemo(() => {
    return {
      ...defaultOptions as Options,
      series: [
        {
          name: 'Geral',
          data: getData(({ total }) => Math.floor(total)),
          color: 'var(--primary-color)',
          lineWidth: 2,
        } as SeriesOptionsType,
        {
          name: 'LC',
          data: getData(({ lc }) => Math.floor(lc ?? 0)),
          color: 'var(--secondary-color)',
          lineWidth: 2,
          visible: false,
        } as SeriesOptionsType,
        {
          name: 'MT',
          data: getData(({ mt }) => Math.floor(mt ?? 0)),
          color: 'var(--danger-color)',
          lineWidth: 2,
          visible: false,
        } as SeriesOptionsType,
        {
          name: 'CN',
          data: getData(({ cn }) => Math.floor(cn ?? 0)),
          color: 'var(--success-color)',
          lineWidth: 2,
          visible: false,
        } as SeriesOptionsType,
        {
          name: 'CH',
          data: getData(({ ch }) => Math.floor(ch ?? 0)),
          color: 'var(--warning-color)',
          lineWidth: 2,
          visible: false,
        } as SeriesOptionsType,
      ],
      xAxis: {
        ...defaultOptions.xAxis,
        categories: months,
      } as Options['xAxis'],
    }
  }, [getData, months])

  useEffect(() => {
    const chart = highchartsRef.current?.chart

    if (!chart || isLoading || options.series?.length === 0)
      return

    chart.update(options)
  }, [isLoading, options])

  return (
    <HighchartsReact ref={highchartsRef} highcharts={Highcharts} options={options} />
  )
}

export default Grade
