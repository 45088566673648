export const getYoutubeVideoId = (url: string) => {
  const regex = /(?:https?:\/\/)?(?:www\.)?youtube\.com\/watch\?v=([a-zA-Z0-9_-]{11})/
  const match = url.match(regex)

  return match ? match[1] : null
}

export const keyOf = <T>(obj: T): keyof T => {
  return Object.keys(obj as any).find(key => (obj as any)[key] === obj) as keyof T
}
