import axios from 'axios'
import { QueryFunction } from '@tanstack/react-query'
import IEvaluation from '@/interfaces/IEvaluation'

const all: QueryFunction<IEvaluation[]> = ({ queryKey: [ , id ] }) => {
  return axios.get(`assessments/${id}/evaluations`).then(({ data }) => data)
}

const $Evaluation = {
  all,
}

export default $Evaluation
