import axios from 'axios'

axios.defaults.baseURL = process.env.REACT_APP_API
axios.defaults.headers.common['Content-Type'] = 'application/json'
axios.defaults.headers.common['Accept'] = 'application/json'

try {
  const stickyValue = localStorage.getItem('token')

  if (stickyValue) {
    const { token }: { token: string | null } = JSON.parse(stickyValue)

    if (token && token.length > 0) {
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
    }
  }
} catch (e) {
  console.error(e)
}
