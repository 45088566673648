import IEvaluation from '@/interfaces/IEvaluation'
import React from 'react'
import { Container } from './Evaluation.styles'
import OpenEvaluationStatusEnum from '@/enums/OpenEvaluationStatus'
import moment from 'moment'
import { FaBook, FaRegCalendarDays } from 'react-icons/fa6'
import { useMutation } from '@tanstack/react-query'
import { Spinner } from 'react-bootstrap'
import IAssessment from '@/interfaces/IAssessment'
import $SSO from '@/services/SSO'

const icons = {
  CH: require('./../../../../../assets/imgs/areas/CH.png'),
  CN: require('./../../../../../assets/imgs/areas/CN.png'),
  LC: require('./../../../../../assets/imgs/areas/LC.png'),
  MT: require('./../../../../../assets/imgs/areas/MT.png'),
}

type Props = {
  evaluation: IEvaluation
}

const Evaluation: React.FC<Props> = ({ evaluation: { id, name, area, openEvaluation } }) => {
  const isOutOfDate = moment().isBefore(openEvaluation.startAt) || moment().isAfter(openEvaluation.endAt)

  const { mutateAsync, isPending, isSuccess } = useMutation({
    mutationFn: $SSO.evaluation,
  })

  const redirectToEvaluation = async () => {
    if (isOutOfDate)
      return

    const { data: { redirectUrl } } = await mutateAsync(id)

    window.location.href = redirectUrl
  }

  return (
    <Container className={`evaluation area-${area.shortname}`}>
      <div className="heading mb-2">
        <img src={icons[area.shortname]} className="img-fluid" alt={area.name} />
        <h5>{name}</h5>
      </div>

      <div className="description">
        <div className="date">
          <FaRegCalendarDays color="#777" /> Disponível entre <span>{moment(openEvaluation.startAt).format('DD/MM/YYYY HH:mm')}</span> e <span>{moment(openEvaluation.endAt).format('DD/MM/YYYY HH:mm')}</span>
        </div>

        <div className="area">
          <FaBook color="#777" /> {area.shortname}
        </div>
      </div>

      {openEvaluation.status !== OpenEvaluationStatusEnum.Completed && (
        <div className="btn-evaluation mt-2">
          {isOutOfDate ? (
            <button className="btn btn-secondary btn-sm mt-2" disabled>
              Fora do período de aplicação
            </button>
          ) : (
            <>
              {openEvaluation.status === OpenEvaluationStatusEnum.Available && (
                <button className="btn btn-primary btn-sm mt-2" onClick={redirectToEvaluation} disabled={isPending || isSuccess}>
                  <span>Iniciar prova</span> {(isPending || isSuccess) && <Spinner animation="border" size="sm" />}
                </button>
              )}

              {openEvaluation.status === OpenEvaluationStatusEnum.InProgress && (
                <button className="btn btn-primary btn-sm mt-2" onClick={redirectToEvaluation} disabled={isPending || isSuccess}>
                  <span>Continuar prova</span> {(isPending || isSuccess) && <Spinner animation="border" size="sm" />}
                </button>
              )}
            </>
          )}
        </div>
      )}
    </Container>
  )
}

export default Evaluation
