import React, { createContext } from 'react'
import useStickyState from '@/hooks/useStickyState'

type AppContextProps = {
  token: string | null
  setToken: React.Dispatch<React.SetStateAction<string | null>>
}

const AppContext = createContext<AppContextProps>({} as AppContextProps)

export const AppProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [ token, setToken ] = useStickyState<string | null>('token', null)

  return (
    <AppContext.Provider
      value={{
        token,
        setToken,
      }}
    >
      {children}
    </AppContext.Provider>
  )
}

export default AppContext
