import styled from 'styled-components'

export const Page = styled.div`
  > .composition-name {
    background-color: #fff;
    border-radius: var(--border-radius);
    box-shadow: var(--box-shadow);
    padding: 1rem 1.6rem;

    > h5 {
      color: var(--text-color);
      font-weight: 500;
      font-size: 1rem;
    }
  }

  > .row {
    display: flex;
    align-content: stretch;

    > .col {
      > .card {
        > .card-body {
          padding: calc(var(--padding) / 2);
        }

        &.my-grade > .card-body > .tab-content > .tab-pane {
          > .feedback {
            height: 440px;
            overflow-y: scroll;
            padding-right: calc(var(--padding) / 2);

            > .geral {
              text-align: center;
              background-color: var(--primary-color);
              border-radius: 0.375rem;
              padding-bottom: 0.5rem;
              padding-top: 0.5rem;
              font-weight: 500;
              color: #fff;

              > span {
                font-size: 1.429rem;
                margin-left: 0.25rem;
              }
            }

            > .card,
            > .comment > .card {
              > .card-header {
                border-radius: 0.375rem 0.375rem 0 0;
                padding-bottom: 1rem;
                padding-top: 1rem;
              }
            }

            > .card > .card-header {
              background-color: var(--tertiary-color);
            }
          }

          > .proposal-text {
            height: calc(440px - 1rem);
            overflow-y: scroll;
            border: 1px solid #f0f0f0;
            padding: var(--padding);
          }
        }

        &.user-composition > .card-body > .inner-content {
          height: calc(486px - calc(var(--padding) / 2));
          font-size: 1.125rem;
          line-height: 1.925rem;
          border: 1px solid #f0f0f0;
          padding: var(--padding);
          overflow-y: scroll;
        }
      }
    }
  }
`
