import { useCallback, useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { CompositionThemeContext } from '@/contexts/CompositionTheme'
import { CompositionState } from '@/models/Composition'
import useSwal from '@/hooks/useSweetAlert'

const useSubmitted = () => {
  const { $compositionTheme, $update, compositionRef, setIsFinished, isAvailable } = useContext(CompositionThemeContext)

  const swal = useSwal()

  const navigate = useNavigate()

  const onSubmit = useCallback(async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    if (!$compositionTheme.data || !isAvailable)
      return

    const { data: { id, name } } = $compositionTheme

    compositionRef.current.swipe(CompositionState.Submitted, (content) => {
      swal.fire({
        title: name,
        html: (
          '<p>Sua redação será finalizada e enviada.</p>' +
          '<strong>Deseja continuar?</strong>'
        ),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não',
      }).then(async (result) => {
        if (result.isConfirmed) {
          setIsFinished(true)

          swal.fire({
            title: 'Finalizando redação',
            html: (
              '<p>Aguarde enquanto finalizamos a sua redação</p>' +
              '<strong>Por favor, não feche a página</strong>'
            ),
            showCancelButton: false,
            allowOutsideClick: false,
            showCloseButton: false,
            didOpen: () => swal.showLoading(),
          })

          await $update.mutateAsync(content)

          swal.fire({
            title: 'Redação finalizada',
            html: (
              '<p>A sua redação foi finalizada e enviada com sucesso</p>' +
              '<strong>Obrigado por participar!</strong>'
            ),
            icon: 'success',
          })

          navigate(`/redacao/${id}/resultado`)
        }
      })
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [$compositionTheme, isAvailable])

  return {
    $update,
    onSubmit,
  }
}

export default useSubmitted
