import { useContext, useEffect, useState } from 'react'
import { CompositionThemeContext } from '@/contexts/CompositionTheme'
import { getTimeRemaining } from '@/helpers/Time'
import CompositionThemeStatus from '@/enums/CompositionThemeStatus'

const useTimeRemaining = () => {
  const { $compositionTheme, compositionRef } = useContext(CompositionThemeContext)

  const [ timeRemaining, setTimeRemaining ] = useState<number | null>(null)

  useEffect(() => {
    if (!$compositionTheme.data)
      return

    const { counterTime, userComposition: { createdAt }, status } = $compositionTheme.data

    if (!counterTime || status !== CompositionThemeStatus.Available)
      return

    setTimeRemaining(() => {
      return getTimeRemaining(counterTime, new Date(createdAt))
    })

    const counter = setInterval(() => setTimeRemaining(() => {
      const timeRemaining = getTimeRemaining(counterTime, new Date(createdAt))

      if (compositionRef.current.getState() !== null) {
        clearInterval(counter)
      }

      if (!timeRemaining) {
        clearInterval(counter)
        return 0
      }

      return timeRemaining
    }), 1000)

    return () => {
      clearInterval(counter)
    }
  }, [$compositionTheme.data, compositionRef])

  return timeRemaining
}

export default useTimeRemaining
