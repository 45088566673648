import React, { useContext } from 'react'
import AuthContext from '@/contexts/Auth'
import { Link } from 'react-router-dom'
import { Container } from './Header.styles'

const Header: React.FC = () => {
  const { user } = useContext(AuthContext)

  return (
    <Container>
      <div className="container-fluid d-flex justify-content-between align-items-center">
        <figure className="logotipo mb-0">
          <Link to="/">
            <img src={user.client.logotipo.url} className="img-fluid" alt={user.client.name} />
          </Link>
        </figure>
      </div>
    </Container>
  )
}

export default Header
