import React from 'react'
import { FaClock } from 'react-icons/fa6'
import moment from 'moment'
import useTimeRemaining from '@/hooks/CompositionTheme/useTimeRemaining'
import { Timer } from './Contdown.styles'

const Countdown: React.FC = () => {
  const timeRemaining = useTimeRemaining()

  if (timeRemaining === null)
    return null

  return (
    <Timer className={timeRemaining <= 60 ? 'is-dangerous' : ''}>
      <FaClock color={timeRemaining <= 60 ? '#f00' : ''} />
      <span>{moment.utc(moment.duration(timeRemaining, 'seconds').asMilliseconds()).format('HH:mm:ss')}</span>
    </Timer>
  )
}

export default Countdown
