import { useEffect, useRef, useState } from 'react'

const useDebounce = (value: string | null, delay = 500) => {
  const [ debounceValue, setDebounceValue ] = useState<string | null>(value)

  const debounceRef = useRef<NodeJS.Timeout>()

  useEffect(() => {
    clearTimeout(debounceRef.current)

    if (value !== null) {
      debounceRef.current = setTimeout(() => {
        setDebounceValue(value)
      }, delay)
    }

    return () => {
      clearTimeout(debounceRef.current)
    }
  }, [value, delay])

  return debounceValue
}

export default useDebounce
