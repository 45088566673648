import { useContext, useEffect, useRef } from 'react'
import { CompositionThemeContext } from '@/contexts/CompositionTheme'
import useSwal from '@/hooks/useSweetAlert'
import { getTimeRemaining } from '@/helpers/Time'
import CompositionThemeStatus from '@/enums/CompositionThemeStatus'

const useOneMinuteLeft = () => {
  const { $compositionTheme, compositionRef } = useContext(CompositionThemeContext)

  const swal = useSwal()
  const oneMinuteLeftRef = useRef(false)

  useEffect(() => {
    if (!$compositionTheme.data)
      return

    const { counterTime, userComposition: { createdAt }, status } = $compositionTheme.data

    if (!counterTime || status !== CompositionThemeStatus.Available)
      return

    const timeRemaining = getTimeRemaining(counterTime, new Date(createdAt))

    const timeout = setTimeout(() => {
      if (oneMinuteLeftRef.current || compositionRef.current.getState() !== null)
        return

      swal.fire({
        title: 'Atenção',
        text: 'Sua redação será finalizada e enviada automaticamente em 1 minuto',
        icon: 'warning',
        showCancelButton: false,
        confirmButtonText: 'Entendi',
        timer: 10 * 1000,
      })

      oneMinuteLeftRef.current = true
    }, Math.max((timeRemaining - 60) * 1000, 0))

    return () => {
      clearTimeout(timeout)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [$compositionTheme])
}

export default useOneMinuteLeft
