import { useCallback, useContext, useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { CompositionThemeContext } from '@/contexts/CompositionTheme'
import { CompositionState } from '@/models/Composition'
import useSwal from '@/hooks/useSweetAlert'

const useWantsToExit = () => {
  const { compositionRef, $update, isAvailable } = useContext(CompositionThemeContext)

  const swal = useSwal()
  const containerRef = useRef<HTMLDivElement>(null)

  const navigate = useNavigate()

  const onTryExit = useCallback((event: MouseEvent) => {
    const target = event.currentTarget as Node | null

    if (containerRef.current && target && containerRef.current.contains(target))
      return

    event.preventDefault()

    const to = (event.currentTarget as HTMLAnchorElement).getAttribute('href') ?? '/'

    if (!isAvailable) {
      navigate(to)
      return
    }

    swal.fire({
      title: 'Sair sem finalizar?',
      html: (
        '<p>Seu texto será salvo e você poderá continuar depois, até o fim do cronômetro ou da janela de aplicação.</p>' +
        '<strong>Deseja continuar?</strong>'
      ),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não',
    }).then(async ({ isConfirmed }) => {
      if (isConfirmed && compositionRef.current.getState() === null) {
        compositionRef.current.swipe(CompositionState.Exited, async (content) => {
          swal.showLoading()

          await $update.mutateAsync(content).finally(() => {
            swal.close()
          })

          navigate(to)
        })
      }
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAvailable])

  useEffect(() => {
    const elements = document.querySelectorAll('a')

    elements.forEach((element) => {
      element.addEventListener('click', onTryExit)
    })

    return () => {
      elements.forEach((element) => {
        element.removeEventListener('click', onTryExit)
      })
    }
  })

  return {
    containerRef,
  }
}

export default useWantsToExit
