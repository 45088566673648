import React from 'react'
import { QueryClientProvider } from '@tanstack/react-query'
import reportWebVitals from './reportWebVitals'
import ReactDOM from 'react-dom/client'
import Routes from './routes/Router'
import { AppProvider } from '@/contexts/App'
import { config } from './app/bootstrap'
import moment from 'moment'

import 'moment/locale/pt-br'

moment.locale('pt-br')

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
)

root.render(
  <QueryClientProvider client={config.queryClient}>
    <AppProvider>
      <Routes />
    </AppProvider>
  </QueryClientProvider>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
