import { Link, useParams } from 'react-router-dom'
import React, { useMemo } from 'react'
import { Nav, Tab } from 'react-bootstrap'
import { useQuery } from '@tanstack/react-query'
import { FaBook, FaListAlt } from 'react-icons/fa'
import Skeleton from 'react-loading-skeleton'
import { Page } from './UserComposition.styles'
import Comment from './Comment/Comment'
import CompositionCriteriaEnum from '@/enums/CompositionCriteria'
import { getYoutubeVideoId } from '@/helpers/Utils'
import $CompositionTheme from '@/services/CompositionTheme'

const UserComposition: React.FC = () => {''
  const { id } = useParams()

  const $compositionTheme = useQuery({
    queryFn: $CompositionTheme.find,
    refetchOnMount: true,
    queryKey: [
      'composition-theme', Number(id),
    ],
  })

  const $userComposition = useQuery({
    queryFn: $CompositionTheme.userComposition,
    refetchOnMount: true,
    queryKey: [
      'user-composition', Number(id),
    ],
  })

  const compositionTheme = useMemo(() => {
    return $compositionTheme.data || null
  }, [$compositionTheme.data])

  const userComposition = useMemo(() => {
    return $userComposition.data || null
  }, [$userComposition.data])

  const content = useMemo(() => {
    if (compositionTheme?.userComposition.content)
      return compositionTheme.userComposition.content

    if (userComposition?.content)
      return userComposition.content

    if ($compositionTheme.isLoading || $userComposition.isLoading)
      return null

    return ''
  }, [compositionTheme, userComposition, $compositionTheme.isLoading, $userComposition.isLoading])

  return (
    <Page className="container pt-5 pb-5">
      <div className="d-flex justify-content-between align-items-center">
        <h3 className="mb-3">Tema</h3>

        <Link to="/" className="btn btn-primary mb-3">
          Voltar para home
        </Link>
      </div>

      <div className="composition-name mb-3">
        <h5 className="mb-0">
          {compositionTheme?.name ? compositionTheme.name : <Skeleton />}
        </h5>
      </div>

      <div className="row">
        <div className="col col-12 col-md-6 mb-3">
          <div className="card my-grade">
            <div className="card-header">
              <h6 className="mb-0">Minhas notas</h6>
            </div>

            <div className="card-body pb-0">
              <Tab.Container defaultActiveKey="feedback">
                <Nav variant="pills" className="flex-row justify-content-center mb-3">
                  <Nav.Item>
                    <Nav.Link eventKey="feedback">
                      <FaListAlt size={16} /> Ver devolutiva
                    </Nav.Link>
                  </Nav.Item>

                  <Nav.Item>
                    <Nav.Link eventKey="proposal-text">
                      <FaBook size={16} /> Ver proposta
                    </Nav.Link>
                  </Nav.Item>
                </Nav>

                <Tab.Content>
                  <Tab.Pane eventKey="feedback">
                    <div className="feedback">
                      {userComposition?.geral ? (
                        <div className="geral mb-3">
                          <small>Nota</small> <span>{userComposition.geral}</span>
                        </div>
                      ) : (
                        <Skeleton height={58} className="mb-3" />
                      )}

                      <div className="card proposal-comments mb-3">
                        <div className="card-header">
                          <h6 className="mb-0">Proposta comentada</h6>
                        </div>

                        <div className="card-body p-0">
                          {compositionTheme?.proposalVideo ? (
                            <iframe
                              width="100%"
                              height="200"
                              src={`https://www.youtube.com/embed/${getYoutubeVideoId(compositionTheme.proposalVideo)}`}
                              title="Proposta comentada"
                              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                              referrerPolicy="strict-origin-when-cross-origin"
                              allowFullScreen
                            />
                          ) : (
                            <Skeleton height={200} />
                          )}
                        </div>
                      </div>

                      {userComposition?.comments ? Object.entries(userComposition.comments).map<JSX.Element>(([key, comment]) => {
                        const tag = key as keyof typeof CompositionCriteriaEnum

                        return (
                          <div className="comment mb-3" key={tag}>
                            <Comment tag={tag} comment={comment} value={Number(userComposition[tag])} />
                          </div>
                        )
                      }) : (
                        <>
                          <Skeleton height={65} className="mb-0" />

                          <div className="p-3">
                            <Skeleton width={100} />
                            <br/>
                            <Skeleton count={3} />
                            <br/>
                            <Skeleton />
                            <Skeleton width={200} />
                          </div>
                        </>
                      )}
                    </div>
                  </Tab.Pane>

                  <Tab.Pane eventKey="proposal-text">
                    <div className="proposal-text overflow-y-scroll mb-3">
                      {!$compositionTheme.isLoading ? (
                        <div dangerouslySetInnerHTML={{ __html: compositionTheme?.proposalText ?? '' }} />
                      ) : (
                        <>
                          <Skeleton width={160} />
                          <br/>
                          <Skeleton count={3} />
                          <br/>
                          <Skeleton width={200} />
                          <br/>
                          <Skeleton count={3} />
                        </>
                      )}
                    </div>
                  </Tab.Pane>
                </Tab.Content>
              </Tab.Container>
            </div>

            <div className="card-footer"></div>
          </div>
        </div>

        <div className="col col-12 col-md-6 mb-3">
          <div className="card user-composition">
            <div className="card-header">
              <h6 className="mb-0">Minha redação</h6>
            </div>

            <div className="card-body">
              <div className="inner-content">
                {content ? (
                  <div dangerouslySetInnerHTML={{ __html: (
                    content.replaceAll('\n', '<br />')
                  ) || '' }} />
                ) : (
                  <>
                    <Skeleton className="mb-3" width={160} count={1} />
                    <br/>
                    <Skeleton className="mb-3" count={3} />
                    <br/>
                    <Skeleton className="mb-3" width={200} count={1} />
                    <br/>
                    <Skeleton className="mb-3" count={3} />
                  </>
                )}
              </div>
            </div>

            <div className="card-footer"></div>
          </div>
        </div>
      </div>
    </Page>
  )
}

export default UserComposition
