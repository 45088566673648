import styled from 'styled-components'

export const Container = styled.div`
  background-color: var(--primary-color);
  padding: 1rem;
  color: #fff;

  > .container-fluid {
    > .logotipo {
      > a {
        > img {
          max-height: 56px;
        }
      }
    }
  }
`
