import styled from 'styled-components'
import { Modal as ModalComponent, Accordion as AccordionComponent } from 'react-bootstrap'

export const Modal = styled(ModalComponent)`
  > .modal-dialog > .modal-content > .modal-body {
    > .btn-result {
      display: flex;
      justify-content: center;

      > .btn {
        width: 100%;
      }
    }
  }
`

export const Accordion = styled(AccordionComponent)`
  > .accordion-item {
    margin-bottom: 1rem;
    border: 0;

    > .accordion-header {
      margin-bottom: 0.5rem;
      border: 0;

      > .accordion-button {
        display: flex;
        justify-content: flex-start;
        font-size: 1rem;
        color: var(--text-color);
        background-color: transparent;
        box-shadow: none;
        outline: none;
        padding: 0;
        border: 0;
        gap: 0.5rem;

        &::before {
          content: '';
          width: var(--bs-accordion-btn-icon-width);
          height: var(--bs-accordion-btn-icon-width);
          background-repeat: no-repeat;
          background-image: var(--bs-accordion-btn-icon);
          background-size: var(--bs-accordion-btn-icon-width);
          transition: var(--bs-accordion-btn-icon-transition);
          transform: rotate(180deg);
        }

        &:not(.collapsed)::before {
          transform: rotate(0deg);
        }

        &::after {
          display: none;
        }
      }
    }

    > .accordion-collapse > .accordion-body {
      > .evaluations {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
      }
    }
  }
`
