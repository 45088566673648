import styled from 'styled-components'

export const Container = styled.header`
  background-color: var(--primary-color);
  padding-bottom: 1rem;
  padding-top: 1rem;
  color: #fff;

  > .container-fluid {
    > .logotipo {
      > a {
        > img {
          max-height: 52px;
        }
      }
    }

    > .btn {
      font-size: var(--font-size-16px);
      font-weight: 500;
    }
  }
`
