import React, { createContext, useEffect, useMemo, useRef, useState } from 'react'
import { Navigate, Outlet, useParams } from 'react-router-dom'
import { useMutation, UseMutationResult, useQuery, UseQueryResult } from '@tanstack/react-query'
import { AxiosResponse } from 'axios'
import $CompositionTheme from '@/services/CompositionTheme'
import ICompositionTheme from '@/interfaces/ICompositionTheme'
import Composition from '@/models/Composition'
import CompositionThemeStatus from '@/enums/CompositionThemeStatus'

type Props = {
  id: number
  content: string | null
  isAvailable: boolean
  isFinished: boolean
  setIsFinished: React.Dispatch<React.SetStateAction<boolean>>
  isTimeExpired: boolean
  setIsTimeExpired: React.Dispatch<React.SetStateAction<boolean>>
  compositionRef: React.MutableRefObject<Composition>
  $compositionTheme: UseQueryResult<ICompositionTheme, Error>
  $update: UseMutationResult<AxiosResponse, Error, string | null>
}

export const CompositionThemeContext = createContext<Props>({} as Props)

const CompositionThemeProvider: React.FC = () => {
  const [ content, setContent ] = useState<string | null>(null)
  const [ isTimeExpired, setIsTimeExpired ] = useState<boolean>(false)
  const [ isFinished, setIsFinished ] = useState<boolean>(false)

  const { id } = useParams()

  const abortControllerRef = useRef<AbortController | null>(null)

  const compositionRef = useRef<Composition>(new Composition(setContent))

  const $compositionTheme = useQuery({
    queryFn: $CompositionTheme.find,
    refetchOnMount: true,
    queryKey: [
      'composition-theme', Number(id),
    ],
  })

  const $update = useMutation({
    mutationFn: (content: string | null) => {
      abortControllerRef.current?.abort()

      abortControllerRef.current = new AbortController()

      return $CompositionTheme.update({
        id: Number(id),
        content,
        abortController: abortControllerRef.current,
      })
    },
  })

  useEffect(() => {
    if ($compositionTheme.data) {
      compositionRef.current.setContent($compositionTheme.data.userComposition?.content)
    }
  }, [$compositionTheme.data])

  const isAvailable = useMemo(() => {
    return $compositionTheme.data?.status === CompositionThemeStatus.Available
  }, [$compositionTheme.data])

  return (
    <CompositionThemeContext.Provider
      value={{
        id: Number(id),
        content,
        isAvailable,
        isTimeExpired,
        setIsTimeExpired,
        isFinished,
        setIsFinished,
        compositionRef,
        $compositionTheme,
        $update,
      }}
    >
      {$compositionTheme.data && [
        CompositionThemeStatus.Unavailable,
        CompositionThemeStatus.Final,
      ].includes($compositionTheme.data.status) ? (
        <Navigate to={`/redacao/${id}/resultado`} />
      ) : (
        <Outlet />
      )}
    </CompositionThemeContext.Provider>
  )
}

export default CompositionThemeProvider
