import styled from 'styled-components'

export const Timer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.3rem;

  > svg {
    position: relative;
    top: -2px;
  }

  &.is-dangerous {
    color: #f00;
  }
`
