const stickyValue = localStorage.getItem('title')

if (stickyValue && stickyValue.length > 0) {
  try {
    const { title } = JSON.parse(stickyValue)

    if (typeof title === 'string') {
      document.title = title
    }
  } catch (error) {
    console.error(error)
  }
}
