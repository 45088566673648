import axios, { AxiosResponse } from 'axios'
import { QueryFunction, MutationFunction } from '@tanstack/react-query'
import ICompositionTheme from '@/interfaces/ICompositionTheme'
import IUserComposition from '@/interfaces/IUserComposition'

const all: QueryFunction<ICompositionTheme[]> = () => axios.get('composition-themes').then(({ data }) => data)

const find: QueryFunction<ICompositionTheme, [string, number]> = ({ queryKey: [ , id ] }) => {
  return axios.get(`composition-themes/${id}`).then(({ data }) => data)
}

const userComposition: QueryFunction<IUserComposition, [string, number]> = ({ queryKey: [ , id ] }) => {
  return axios.get(`composition-themes/${id}/user-composition`).then(({ data }) => data)
}

const update: MutationFunction<AxiosResponse, { id: number, content: string | null, abortController: AbortController }> = ({ id, content, abortController }) => {
  return axios.put(`composition-themes/${id}`, content, {
    signal: abortController.signal,
  })
}

const $CompositionTheme = {
  all,
  find,
  userComposition,
  update,
}

export default $CompositionTheme
