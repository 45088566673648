import React, { useCallback, useContext, useMemo, useRef } from 'react'
import { CompositionThemeContext } from '@/contexts/CompositionTheme'
import useDebounce from '@/hooks/useDebounce'
import useUpdateEffect from '@/hooks/useUpdateEffect'

const Textarea: React.FC = () => {
  const { isAvailable, content, compositionRef, $update, isTimeExpired, isFinished } = useContext(CompositionThemeContext)

  const textareaRef = useRef<HTMLTextAreaElement>(null!)

  const debouncedValue = useDebounce(content, 500)

  useUpdateEffect(() => {
    if (isAvailable && compositionRef.current.getState() === null) {
      $update.mutate(debouncedValue)
    }
  }, [isAvailable, debouncedValue])

  const onChange = useCallback(({ target: { value } }: React.ChangeEvent<HTMLTextAreaElement>) => {
    if (isAvailable) {
      compositionRef.current.setContent(value)
    }
  }, [compositionRef, isAvailable])

  const canEdit = useMemo(() => {
    return isAvailable && !isTimeExpired && !isFinished
  }, [isTimeExpired, isFinished, isAvailable])

  return (
    <textarea ref={textareaRef} name="content" className="form-control" value={content ?? ''} placeholder="Escreva sua redação aqui..." maxLength={3500} onChange={onChange} disabled={!canEdit} />
  )
}

export default Textarea
